.nav{
  width: 100%;
  height: 50px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}
.nav-group {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo svg{
  font-size: 24px;
}

.nav-group .search-box input{
  background: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: 1px solid black;
  outline: none;
  color: black;
}
.nav-group .search-box svg{
  font-size: 14px;
  color: #797589;
}

.links {
  width: 35%;
}

.links ul{
  display: flex;
  justify-content: space-evenly;
}

.links .link{
  color: black;
}