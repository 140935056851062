.banner{
  background: #dfdad5;
  width: 100%;
  height: 300px;
  position: relative;
}

.banner-img{
  position: relative;
width: 100%;
height: 100%;
overflow: hidden;
margin: 0 auto;
}

.banner-img img{
  position: absolute;
  width: 100%;
  height: 100%;
  left: -1300px;
  opacity: 0;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
}
.show {
opacity: 1 !important;
left: 0 !important;
}
.hide{
  opacity: 0 !important;
left: 1300px !important;
}

.dots{
  width: 190px;
  height: 35px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.dot{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 3px solid #0000006b;
background-color: #4d4c4c;
}
.active,.dot:hover{
  border-color: rgba(240, 248, 255, 0.493);
  background-color:#000000b8 ;
}
