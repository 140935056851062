body{
  background-color: rgb(246 234 223);
  margin: 0;
  padding: 0;
}
*{
  box-sizing: border-box!important ;
  list-style: none;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}
ul{
  margin: 0;
  padding: 0;
}