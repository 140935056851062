.products{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.products hr{
  height: 250px;
  margin: auto 0;
}
.product{
  position: relative;
  width: 500px;
  height: 525px;
  background: #ffffff61;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 20px 0px #c1c0be;
  margin-top: 64px;
  overflow: hidden;
}
.img{
  width: calc(50% - 15px);
  height: 100%;
  border-right: 1px solid;
  box-shadow: 6px -20px 20px #0000008a;
  display: flex;
  align-items: center;
  background: #fffffc;
}
.img img{
  height: 55%;
  width: 100%;
}
.text{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}
.category{
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1.5px;
}
.heading h1{
  font-size: 35px;
  margin: 0;
  font-family: Montserrat;
}
.p-container{
  color: #35363a82;
}
.price-container{
  display: flex;
  align-items: center;
}
.price-container .price{
  font-family: 'Fraunces', serif;
  font-size: 30px;
  margin-right: 20px;
  font-weight: bolder;
}
.price-container .descount{
  align-self: flex-end;
  text-decoration: line-through;
  color: #35363a82;
  font-size: 18px;
}
.text .btn{
  border: none;
  background: black;
  color: white;
  height: 45px;
  cursor: pointer;
  font-size: 15px;
  margin-right: 10px;
}
.text .btn img{
  margin-right: 5px;
}

.text .btn:hover{
background-color: #414141;
}