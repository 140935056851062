::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #d0d0d0;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(136, 136, 136);
}
::-webkit-scrollbar-thumb:hover {
  background-color: black;
}


.seller{
  width: 100%;
  height: 265px;
  background:white;
  display: flex;
  flex-direction: column;
}
.seller .title{
  position: relative;
  background: black;
  color: white;
  width: 225px;
  height: 34px;
  line-height: 1.9;
  font-size: 18px;
  margin-left: 3px;
  text-indent: 8px;
}
.title::after{
  content: "";
  position: absolute;
  width: 8px;
  right: 0;
  bottom: 0px;
  border-left: 30px solid black;
  border-bottom: 34px solid #ffffff;
}
.seller-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 15px;
  padding: 0 10px;
  margin-top: 5px;
}
.best-seller{
  position: relative;
  min-width: 330px;
  height: 100%;
  display: flex;
  box-shadow: inset -20px 1px 20px 0px #f5f5f5;
  overflow: hidden
}
.order{
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 44px;
  background: black;
  color: white;
  text-align: center;
  font-weight: 900;
}
.order::after{
  content: "";
  position: absolute;
  background: #f1f1f1;
  left: 20%;
  bottom: 0px;
  border-left: 8px solid black;
  border-bottom: 14px solid white;
  border-right: 8px solid black;
}
.seller-img{
width: 50%;
display: flex;
align-items: center;
}
.best-seller .text{
  width: 50%;

}

.best-seller .seller-img img{
width: 100px;
}
.best-seller .text .heading h3{
  font-size: 18px;
  margin: 0;
}
.best-seller .text .paragraph{
  font-size: 12px;
}
.best-seller .text .rating , .title svg{
  color: #ffcc5a;
}
.best-seller .text .price span{
  font-size: 25px;
}
.seller-price{
  display: flex;
}
.seller-price .price{
  font-family: 'Fraunces', serif;
  font-size: 23px;
  margin-right: 10px;
  font-weight: bolder;
}
.seller-price .descount{
  align-self: flex-end;
  text-decoration: line-through;
  color: #35363a82;
  font-size: 12px;
}


.w{
  width: 160px !important;
}
.m{
  margin-top: 30px;
  height: 330px !important;
}
.i{
  width: 115px;
}
.offer{
  width: 30px;
  height: 110px;
  position: absolute;
  background: red;
  transform: rotate(226deg);
  left: 17px;
  top: -22px;
  writing-mode: vertical-rl;
  text-indent: 26px;
  line-height: 30px;
  color: white;
  font-weight: 900;
}