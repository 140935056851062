.controls{
  position: relative;
  height: 55px;
  margin-top: 15px;
}
.controls .control{
  width: 100%;
  height:  55px;
  background: white;
  top: 0;
  z-index: 999;
}
.p-fixed{
  position: fixed;
}

.control{
  display: flex;
  justify-content: flex-end;
  gap: 5px;}


  .control .search-box input{
    width: 480px;
  height: 100%;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  font-size: 15px;
}
.control .search-box button{
  background: black;
  color: white;
  outline: none;
  border: none;
  width: 125px;
  height: 40px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}
.control .search-box button:hover{
  background-color: #414141;
}

.viewer,
.sorting,
.filters{
  width: 200px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: inset 20px 0px 20px 0px #f5f5f5;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.viewer:hover,
.sorting:hover,
.filters:hover{
  box-shadow: inset 0px -20px 20px 0px #e3e3e3;
}
.viewer:hover svg,
.sorting:hover svg,
.filters:hover svg{
color: #727272;
}
.viewer svg,
.sorting svg,
.filters svg{
  margin-left: 5px;
}
.filters .filter-menu,
.sorting .sort-menu,
.viewer .view-menu{
  width: 100%;
  height: fit-content;
  background: white;
  position: absolute;
  padding: 15px;
  z-index: 999;
  bottom: 0;
  display: none;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: inset 0px 20px 20px 0px #f4f4f4;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.filters:hover .filter-menu,
.sorting:hover .sort-menu,
.viewer:hover .view-menu{
  top: 55px;
  display: block;
  opacity: 1;
}
.filter,
.sort,
.view{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.filter:hover,
.sort:hover,
.view:hover{
  background-color: black;
  color: white;
}

.sort-menu{
  height: fit-content !important;
}

.sort-menu .s-three{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.sort-menu .s-three>:nth-child(1){
  padding: 10px;
}
.sort-menu .s-three .submenu{
  width: 100%;
  height: 0px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  display: none;
  opacity: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.sort-menu .s-three .submenu span{
  padding: 2px;
}
.sort-menu .s-three .submenu span:hover{
  background-color: #c4c4c4;
}
.sort-menu .s-three .submenu svg{
  margin-right: 3px;
  width: 20px;
}
.sort-menu .s-three:hover .submenu{
  height: 100%;
  padding: 5px;
  display: flex;
  opacity: 1;
}
.sort-menu .s-three:hover{
  height: 200px;
}

.view-menu .view{
  justify-content: flex-start;
}
.view-menu .view svg{
  margin-right: 3px;
}